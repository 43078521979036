import Loadable from "@loadable/component";

import AsyncLoadingEntityCard from "../AsyncLoadingEntityCard";

const ReviewCardContainer = Loadable(() => import("./ReviewCardContainer"));

function ContainerAsyncLoading(props) {
  return <AsyncLoadingEntityCard {...props} />;
}

const ReviewCardContainerContainer = (props) => {
  return (
    <ReviewCardContainer {...props} fallback={<ContainerAsyncLoading />} />
  );
};

export default ReviewCardContainerContainer;
