import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";

import ReviewCard from "components/Entities/Cards/ReviewCards/ReviewCardContainerAsync";
import entityList from "components/Entities/EntityList";

import cardStyles from "../../../styles/CardStyles";

import loadReviewsList from "sagas/pagination/lists/loadReviewsList";
import { selectSpecificReview } from "selectors/reviews";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

const baseStyles = {
  reviewContainer: {
    ...cardStyles.cardContainer,
  },
};

const ReviewListContainer = (props) => {
  const { listKey, defaultSort, staticFilters, clearOnLogout } = props;

  const { styles } = useStyles(baseStyles, props);

  const { isWindowSizeOrLess } = useWindowSize();
  const isMediumOrLess = isWindowSizeOrLess("medium");

  const ReviewsList = useMemo(
    () =>
      entityList({
        key: listKey,
        sort: defaultSort,
        pageSize: 20,
        list_type: "reviews_filtered",
        loadListAction: loadReviewsList,
        entity_type: "review",
        staticFilters,
        getEntity: (state, id) => selectSpecificReview(state, id),
        clear_on_logout: clearOnLogout,
      }),
    [defaultSort, listKey, staticFilters, clearOnLogout]
  );

  const renderItem = useCallback(
    (review) => (
      <div className={css(styles.reviewContainer)} data-id="review-container">
        <ReviewCard
          entity={review}
          mobile={isMediumOrLess}
          noPanel
          hideBottomRow
          showActionsInReviewBubble
          noSidePadding
          noVerticalPadding
          showInfoIcons
          showReplies
          hideRepliesToggle
        />
      </div>
    ),
    [isMediumOrLess, styles.reviewContainer]
  );

  return (
    <div>
      <ReviewsList
        renderItem={renderItem}
        showLoadMore={false}
        staticFilters={staticFilters}
      />
    </div>
  );
};

ReviewListContainer.propTypes = {
  listKey: PropTypes.string.isRequired,
  defaultSort: PropTypes.string,
  staticFilters: PropTypes.object,
  clearOnLogout: PropTypes.bool,
};

ReviewListContainer.defaultProps = {
  defaultSort: null,
  staticFilters: null,
  clearOnLogout: false,
};

export default memo(ReviewListContainer);
